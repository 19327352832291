/* .cnt1{
    border: 1px solid red;
    width: 100%;
    display: flex;
    justify-content: center;
} */
.cnt2{
     width: 70%;
     display: flex;
     margin-top: 6.5vh;
     /* background-color: white; */

}
.cnt3{
    width: 100%;
    margin-top: 2%;

}
#jyrfu{
border: none;
background-color: #000;
    width: 90%;
    /* border: 1px solid red; */
}
.cnt31{
    color:#2D3133;
    font-family: Medium;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px; 
}

.cnt32 {
    color: #2D3133;
    font-family: Regular;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding-top: 5%;
}
.cnt32 span{
    color: #2D3133;
    font-family: Medium;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; 
}



.cnt4{
    width: 100%;
    margin-bottom: 10%;
   
}
.EFCEARW{
    margin-top: -2px;
    /* margin-bottom: 10px; */
}
.cnt5{
    width: 100%;

}
.cnt41{
    margin-top: 3%;
}
.cnt41 input{
      width: 95%;
      background: #EAE9E9;
      height: 55px;
      flex-shrink: 0;
      border: none;
      outline: none;
      color: #000;
    font-family: Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
    padding-left: 3%;
    margin-top: 3%;
}

.cnt41 label{
    color: #2D3133;
    font-family: Medium;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; 
}
.cnt41 label span{
    color: rgba(45, 49, 51, 0.50);
    font-family:Medium;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}
.cnt41 ::placeholder{
    color: #000;
    font-family: Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
    opacity: 0.5;
    margin-left: 5%;
}

.cnt41 select{
    width: 100%;
    background: #EAE9E9;
    /* width: 242px; */
    height: 55px;
    flex-shrink: 0;
    border: none;
    outline: none;
}
.cnt41 textarea{
    width: 92%;
    background: #EAE9E9;
    border: none;
    margin-top: 3%;

    outline: none;
    padding: 3%;

}

#cnt41{
    display: flex;
    justify-content: space-between;
}
.cnt42 {
    width: 49.8%;
}
.cnt43{
    width: 50%;
}
.cnt44 select {
    opacity: 0.5;
    color: #000;
    font-family: Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
    background-color: #EAE9E9;
padding: 5%; 
 

}


.cnt44{
    width: 98%;
    background-color: #EAE9E9;

}
.chan{
    width: 100%;
}


.subbtn{
    margin-top: 3%;

}
.subbtn1{
   
}
.subbtn1 button{
    color: #2D3133;
    background-color: white;
    font-family: Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; 
    border-style: solid;
    border-image:  linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%)2;
    width: 242px;
    height: 50px;
    flex-shrink: 0;
}
.subbtn1 button:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    color: #ffff;
    cursor: pointer;
}
.code1{
    display: flex;
    width:100%;
    /* border: 1px solid red; */
}

.code1 select{
    height: 57px;
    width: 30%;
padding: 10px;
    margin-top: 3%;

    /* background: #D9D9D9; */



}

.code2 option{
    color: #000;
    font-family: Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    
}
.code1 input{
    /* border: 1px solid red; */
   width: 65%;
}



@media (max-width:1200px) {
    .cnt42 {
        width: 100%;
    }
    .cnt43{
        width: 100%;
    }
    .cnt2{
        width: 85%;
        display: flex;
        margin-top: 6.5vh;
   
   }
    #cnt41{
        display: flex;
        flex-direction: column;
    }
    .cnt42{
        width: 100%;
    }
}
@media (max-width:700px) {
    
    .code1 {
        /* border: 1px solid red; */
        width: 98%;
    }
    .code1 select{
    width: 32%;
    }
        .code1 input{
            color: #000;
            font-family: Medium;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
        }
    
    .cnt42 {
        width: 100%;
    }
    .cnt43{
        width: 100%;
    }
   .cnt2{
    display: flex;
    flex-direction: column;
    width: 90%;
   }
   .cnt4{
    margin-top: 10%;
   }
   .cnt31{
    color: #2D3133;
    font-family:Medium;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; 
   }
   
.cnt32 {
    color: #2D3133;
    font-family: Regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    padding-top: 5%;
}
.cnt32 span{
    color: #2D3133;
    font-family: Medium;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;  
}

.cnt41 label{
    color: #2D3133;
    font-family:Medium;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px; 
}
.cnt41 label span{
    color: rgba(45, 49, 51, 0.50);
    font-family: Medium;
    font-size: 12.386px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
}
.cnt41 ::placeholder{
    color: #000;
    font-family: Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;  
  opacity: 0.5;
  margin-left: 5%;
}


.cnt41 textarea{
  width: 92%;
  background:#EAE9E9;
  border: none;
  margin-top: 3%;

  outline: none;
  padding: 3%;

}
.subbtn{
    margin-top: 10%;

}
.code11{
    margin-top: 5%;
}
.brbr{
    display: none;
}
}