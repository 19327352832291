main{
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    /* border: 1px solid red; */
}
.MainHome{
    /* border: 1px solid yellow; */
    width: 70%;
    margin-top: 5vh;
}
.HomeMain{
    display: flex;
    margin-top: 6.5vh;
    /* flex-wrap: wrap; */
    z-index: 99999;
    justify-content: space-between;
}
.ewrtfw4gf{
    padding-bottom: 25px;
    padding-top: 25px;
}
#jyhguyg{
    margin-top: 70px;
}
.homesol1001{
    display: none;
}
.vhome2{
    display: none;
}
.Title{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* border: 1px solid red; */
    margin-top: 10vh;
}
.rytyrtugh{
    width: 100%;
}
.rytyrtugh img{
    width: 100%;
    height: 100%;
}
.indus2{
    display: none;
}
.HeadText{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
/* color: ; */
}
.uiyji{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;  
}
.titleinner{
    width: 58%;
    color: #2D3133;
    /* text-align: center; */
    font-family: Bold;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 57px; /* 109.615% */
    /* text-transform: capitalize; */
    /* border: 1px solid yellow; */
}

.ConnectHome{

    /* strostke-opacity: 50px; */
    color: white;
    margin-top: 4vh;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-family: Medium;
    width: 189px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);

}
.ConnectHome:hover{

    width: 184px;
    height: 45px;
    cursor: pointer;
    border-style: solid;
    background: white;
    color: #2D3133;
    border-image:  linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%)2;
}
.sene span{
    color: #0A71FE;
font-family: Medium;
font-size: 29px;
font-style: normal;
font-weight: 500;
line-height: 25px; 
}
.sideinner{
    color: #2D3133;
font-family: Medium;
font-size: 24.643px;
font-style: normal;
font-weight: 500;
line-height: 29.643px;
width: 300px;
/* border: 1px solid red; */
}
.Homeinnerfing{
    /* border: 1px solid green; */
    /* height: 10vh; */
    width: 33.3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Homtwoin{
    width: 60%;
    /* border: 1px solid red; */
}
.visi{
    visibility: hidden;
}
.Homeinnerfingtwo{

    /* height: 10vh; */
    /* line-height: 50px; */
    width: 33.3%;
    display: flex;
    justify-content: center;
      /* border: 1px solid green; */

}
.HomeinnerfingThree{
      /* border: 1px solid green; */
    /* height: 10vh; */
    width: 33.3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lineop{
    width: 2.223px;
height: 146.741px;
background: rgba(45, 49, 51, 0.20);
}
.siderinnnertwo{
    width: 100%;
    margin-top: 15px;
    color: #2D3133;
font-family: Regular;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 24px;
    /* border: 1px solid red; */
}
.vhomeIOP{
    margin-top: 40px;
}
.lineheadTwoHome{
    width: 100%;
    margin-top: 50px;
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);

    /* border: 1px solid red; */
}
.HEadliolo{
    margin-top: 18vh;
}
.lineinterhome{
    width: 100%;
    height: 2px;
    background: rgba(45, 49, 51, 0.20);
}

.innerline{
    width: 100%;
    height: 2px;
    margin-top:35px;
    /* border: 1px solid red; */
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);

}
.industryinner{
    width: 100%;
    display: flex;
    justify-content: center;
}

#industryinnertwo{
    color: #2D3133;
    font-family: Medium;
    /* border: 1px solid red; */
    font-size: 57px;
    font-style: normal;
    font-weight: 500;
    line-height: 57px; /* 100% */
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}
.builduo{
    font-family: Bold;
    font-weight: 500;
    color:  hsla(221, 99%, 42%, 1);
}
.yr76f{
    font-family: Bold;
    font-weight: 500;
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.newcontent{
    width: 70%;
    font-size: 42px; 
    font-weight: 500;
    color: #2D3133;
    margin-top: 20px;
    font-family: Medium;
}
.yturf7yug{
    margin-top: 8vh;
}
.xtyfguh{
    display: none;
}
.connecttwo{
    margin-top: 3.5vh;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-family: Medium;
    color: #2D3133;
    width: 242px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-image:  linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%)2;

    /* background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);  */
}
.connecttwo:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    color: #ffff;
}
.industrydatas{
width: 100%;
    
}
.Secondsection{
    margin-top: 20px;
}
.industrydatas img{
    width: 100%;
    height: 100%;
}
.induatryimagedata{

    width: 56px;
height: 43px;
}
.ilyuhiuoh{
    font-family: Medium;
font-weight: 500;
font-size: 57px;
line-height: 100%;
color: #2d3133;
}
.induatryimagedata img{
    width: 100%;
    height: 100%;
}

.arewho{
    display: flex;
    justify-content: space-between;
    height: auto;
    /* margin-top: 40px; */
}
.rtfe{
    margin-top: 40px;
}
.arewhoinsus{
    display: flex;
    justify-content: space-between;
    height: 25vh;
    margin-top: 80px; 
    color: #2D3133;
font-family: Regular;
font-size: 21px;
font-style: normal;
font-weight: 400;
line-height: 25px;
}
.areinner span{
    color: #2D3133;
font-family: Medium;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 25px;
}
#ino{
    font-family: Medium;
    font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 25px;
/* border: 1px solid red; */
}
.areinner{
    width: 50%;
    color: #2D3133;
font-family: Regular;
font-size: 21px;
font-style: normal;
font-weight: 400;
line-height: 25px;
}

.jygjgv{
    /* border: 1px solid red; */
    margin-right: 70px;
    /* margin-left: 100px; */
}
.yttfuygt{
z-index: 9999999;
}
.gfhfcv{
    display: flex;
    justify-content: space-between;
}
.learmore{
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Medium;
font-size: 16px;
font-style: normal;
font-weight: 500;
    border-style: solid;
    border-image:  linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%)2;
}
.learmoretwo{
    display: none;
    margin-top: 60px;
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Medium;
font-size: 16px;
font-style: normal;
font-weight: 500;
    border-style: solid;
    border-image:  linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%)2;
}
.learmoretwo:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    color: #ffff;
}
.learmore:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    color: #ffff;
}
.learnmore{
    width: 242px;
height: 50px;
border: 3px solid #80B4E7;
background: #0A71FE;
display: flex;
justify-content: center;
align-items: center;
color: #EAE9E9;
font-family: Regular;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 21px;
/* z-index: 99999999999999; */
/* border: 1px solid red; */
cursor: pointer;
}
.learnmore:hover{
    border: 3px solid #686b6d;
background-color: #35383a;
}
.solutions{
    width: 100%;
    margin-top: 50px;
}
.solutions img{
    width: 100%;
    height: 100%;
}

.solinner{
    border-right: 1px solid rgba(45, 49, 51, 0.20);

}
.solinner1{
    /* border-top: none; */
   
    border-right: 1px solid rgba(45, 49, 51, 0.20);
    border-bottom: 1px solid rgba(45, 49, 51, 0.20);
}
.solinner3{
    /* border-top: none; */
    border-bottom: 1px solid rgba(45, 49, 51, 0.20);
}
.solinner2{
    /* border-top: none; */
    border-right: 1px solid rgba(45, 49, 51, 0.20);
    border-bottom: 1px solid rgba(45, 49, 51, 0.20);
}
.soluimga{
    width: 75px;
height: 71px;
/* padding-top: 30px; */
/* padding-left: 30px; */
}
.vgfh{
    width: 65%;
    /* border: 1px solid red; */
}
.solutionp{
    color: #2D3133;
font-family: Medium;
font-size: 23px;
font-style: normal;
font-weight: 500;
line-height: 23px; 
margin-top: 20px;
}
.soliutioninner{
    width: 55%;
    color: #2D3133;
font-family: Medium;
font-size: 42px;
font-style: normal;
font-weight: 500;
line-height: 42px;
}
.vghfg{
    width: 70%;
    color: #2D3133;
font-family: Medium;
font-size: 42px;
font-style: normal;
font-weight: 500;
line-height: 42px;
/* border: 1px solid red; */
}
.soluimga img{
width: 100%;
height: 100%;
}
.dep{
    padding-top: 40px;
    /* border: 1px solid red; */
    /* padding-bottom: 40px; */
    color: #2D3133;
font-family: Medium;
font-size: 25px;
font-style: normal;
font-weight: 500;
line-height: 25.722px; 
}
.fuh{
    padding-left: 50px;
    padding-right: 30px;
}
.fuh1{
    padding-right: 30px;

}
.ead{
    padding-right: 30px;

}
.guh{
    padding-top: 40px;
    padding-right: 30px;

}
.Fouthsection{
    /* margin-top: 55px; */
}
.industry{
display: grid;
grid-template-columns: repeat(2 , 1fr);
gap: 20px;
}
.jgjk{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}
.shutter-out-horizontal{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: color 300ms;
    transition: color 300ms;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    /* border: 1px solid red; */

    
 }
.shutter-out-horizontal::before{
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-style: solid;
    background: #2D3133;
    color: #2D3133;
    border-image:  linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%)2;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
}
.shutter-out-horizontal:hover::before{
  

      -webkit-transform: scaleX(1);
      transform: scaleX(1);

}

.sene{
    color: #2D3133;
font-family: Regular;
font-size: 29px;
font-style: normal;
font-weight: 500;
line-height: 29px;
/* margin-top: ; */
}
.ques{
    color: #2D3133;
font-family: Regular;
/* border: 1px solid red; */
font-size: 29px;
font-style: normal;
font-weight: 500;
line-height: 29px;
}
.ques span{
    color: #0A71FE;
font-family: Medium;
font-size: 29px;
font-style: normal;
font-weight: 500;
line-height: 25px; 
}
.linekoko{
    width: 100%;
    height: 1px;
    margin-top: 20px;
    background: #000;
}
.indus{
    width: 351px;
height: 225.739px;
}
.indus img{
    width: 100%;
    height: 100%;
}
.talkus{
    width: 242px;
height: 50px;
border: 3px solid #80B4E7;
background: #0A71FE;
display: flex;
justify-content: center;
align-items: center;
color: #EAE9E9;
font-family: Regular;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 21px;
}
.talkus:hover{
    border: 3px solid #686b6d;
background-color: #35383a;
}
.ideastar{
    margin-top: 20px;
    padding-bottom: 20px;
    color: #2D3133;
font-family: Regular;
font-size: 21px;
font-style: normal;
font-weight: 400;
line-height: 26px;
}
.Fithsection{
    /* margin-top: 55px; */
}
.nttl{
    display: flex;
    /* border: 1px solid red; */
}
.brandgu{
    color: #2D3133;
font-family: Medium;
font-size: 42px;
font-style: normal;
font-weight: 500;
line-height: 42px;
margin-top: 20px;
}
.Brandhuhu{
    color: #000;
font-family: Regular;
font-size: 21px;
font-style: normal;
font-weight: 400;
line-height: 25px;
}

.brand{
    width: 240px;
height: 33.8px;
/* border: 1px solid red; */
}
.brand2{
    width: 170px;
    height: 26px;
    /* border: 5px solid yellow; */
}
.solinner22{
    border-top: none;
    border-right: none;
    border-bottom: 1px solid  rgba(45, 49, 51, 0.20);

    border-left: 1px solid rgba(45, 49, 51, 0.20);
}
.brand2 img{
    width: 100%;
    height: 100%;
}
.brand3{
    width: 151px;
    height: 31px;
    margin-left: 80px;
    /* border: 10px solid red; */
}
.brand3 img{
    width: 100%;
    height: 100%;
}
.brand img{
    width: 100%;
    height: 100%;
}
.brand1{
    width: 200px;
height: 38.8px;
}
.brand1 img{
    width: 100%;
    height: 100%;
}
.huhum{
    display: flex;
    /* justify-content: center; */
    /* border: 1px solid red; */
}
.letstalk{
    margin-top: 100px;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(178deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    color: white;
}
.letstalkprocess{
    margin-top: 20px;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(178deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    color: white;
}
.cyfhv{
    width: 36px;
height: 21px;
/* position: absolute; */
/* right: 40%; */
/* bottom: 0; */
margin-left: 15px;
margin-bottom: 60px;
/* overflow: hidden; */
}
.cyfhv img{
    width: 100%;
    height: 100%;
}
.vhgv {
    display: flex;
    align-items: center;
    color: #EAE9E9;
font-family: Medium;
font-size: 42px;
font-style: normal;
font-weight: 500;
line-height: 42px;
}
.bundles{
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 20px;
}
.bundleinner{
    height: auto;
    border: 0.996px solid #2D3133;
    color: #2D3133;
    font-family: Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
    /* padding: 50px; */
}
.buhead{
    width: 100%;
    display: flex;
    justify-content: center;
}
.schedule{
    width: 60%;
height: 50px;
border: 3px solid #0A71FE;
display: flex;
justify-content: center;
align-items: center;
margin-left: 65px;
}
.schedule:hover{
    background-color: #2D3133;
    color: white;
}
.linek{
    width: 100%;
    height: 0.5px;
    /* position: absolute; */
    left: 0;
    background: rgba(45, 49, 51, 0.20);
}

.hgfgv{
    padding-left: 50px;
  
}
.huihu{
    color: #2D3133;
    font-family:Medium;
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px; 
}

.huihu{
    margin-top: 50px;
}
.hgfgvewdag{
    padding-left: 35px;
}
.insutry{
    margin-top: 80px;
}
.hgv{
    width: 40%;
    color: #2D3133;
    font-family: Medium;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    /* border: 1px solid red; */
}
.sevenko{
    color: #0A71FE;
font-family: Medium;
font-size: 60.03px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
#hiff{
    display: none;
}

@media (max-width : 1528px) {
    .titleinner{
        width: 70%;
        /* border: 1px solid red; */
    }
    .soliutioninner{
        width: 80%;
    }

}
@media (max-width : 1440px) {
    .MainHome{
        /* border: 1px solid yellow; */
        width: 76%;
    }
    .jygjgv{
        /* border: 1px solid red; */
        margin-right: 33px;
        /* margin-left: 100px; */
    }
    .Homtwoin{
        width: 100%;
        padding-left: 50px;
        /* border: 1px solid red; */
    }
    .titleinner{
        /* font-size: 38px; */
  /* border: 1px solid red; */
  width: 80%;
    }
 
    .arewhoinsus{
        height: 35vh;
    }
}
@media (max-width:1250px) {
    .sideinner{
    
    width: 100%;
    /* border: 1px solid red; */
    }
    .bundles{
        display: grid;
        grid-template-columns: repeat(2 , 1fr);
        gap: 20px;
    }
    .MainHome{
        /* border: 1px solid yellow; */
        width: 85%;
    }
    .arewhoinsus{
        height: 34vh;
    }
}
@media (max-width:1000px) {

    .MainHome{
        /* border: 1px solid yellow; */
        width: 90%;
    }
    .vgfh{
        padding-left: 50px;
        /* border: 1px solid red; */
    }

    .brand1{
        width: 20vh;
    height: 33.8px;
    }
}

@media (max-width:800px) {
    .letstalk{
        margin-top: 50px;
    }
    .Fithsection{
        /* margin-top: 5px; */
    }
   .vhome2{
    display: block;
   }
   .vhome{
    display: none;
   }
    .indus2{
        display: block;
    }
    .indus{
        display: none;
    }
    .vhgv {
 
    font-size: 18.85px;

    }
    .cyfhv{
        margin-top: 45px;
        /* margin-bottom: 10px; */
    }
    .sideinner{
    font-size: 23px;
    font-family: Medium;
    }
    #cthfgj{
        margin-top: 50px;
    }
    .Homeinnerfing{
        display: inline;
        /* margin-top: 20px; */
    }
    .lineop{
        display: none;
    }
    .hgv{
        width: 100%;
    }
    .arewhoinsus{
        display: inline;
    }
    .estgfer{
        margin-top: 30px;
    }
 
    .Title{
        margin-top:95px;
    }
    .titleinner{
        color: #2D3133;
        text-align: start;
        font-family: Bold;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 109.615% */
        text-transform: capitalize;
        /* border: 1px solid yellow; */
    }
    .nttl{
        display: flex;
        justify-content: space-between;
        /* border: 1px solid red; */
        /* margin-top: -40px; */
        /* text-align: start; */
    }

    .huhum{
        /* margin-top: 20px; */
        display: inline;
        justify-content: flex-start;
    }
    #mobikl{
        margin-top: 50px;
    }
    .vghfg{
        width: 100%;
    }

    .brand1{
        width: 60%;
        display: flex;
    height: 40px;
    }

    .industry{
        display: inline;
    }
 
    .fuh{
        padding: 0;
        margin-top: 20px;
    }
    .solinner1{
        /* border-top: none; */
        border-right: none;
        /* border-right: 1px solid rgba(45, 49, 51, 0.20); */
        border-bottom: 1px solid rgba(45, 49, 51, 0.20);
    }
    .solinner3{
        /* border-top: none; */
        border-bottom: 1px solid rgba(45, 49, 51, 0.20);
    }
    .solinner2{
        /* border-top: none; */
        border-right: none;
        border-bottom: 1px solid rgba(45, 49, 51, 0.20);

        /* border-right: 1px solid rgba(45, 49, 51, 0.20); */
    }
 
    .arewho{
        display: inline;
        height: auto;
    }
    .areinner{
        padding-top: 15px;
        /* padding-bottom: 0px; */
    }
    .sene{
        margin-top: 40px;
    }
    .titleinner{
        width: 100%;
        /* border: 1px solid red; */
    }
    .HomeMain{
        display: inline;
    }
    .Homeinnerfing{
        margin-top: 40px;
        width: 100%;
    }
    .Homeinnerfingtwo{
        border-left: none;
        border-right:none;
        width: 100%;
        margin-top: 30px;
    }
    .HomeinnerfingThree{
        width: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: flex-start;
    }
    .vgfh{
        padding: 0;
    }
    .Homtwoin{
        padding: 0;
    }
    .areinner{
        width: 100%;
    }
    .indus{
        width: 80%;
        height: 200px;
    }
    .soliutioninner{
        width: 100%;
    }
 
}  
@media (max-width:790px) {
    .ConnectHome{
/* border: 5px solid red; */
        width: 170px;
        height: 45px;
        /* stroke: 150px; */
    }
    .ConnectHome:hover{
        width: 164px;
        height: 39px;
    }

    .connecttwo{
        
        width: 170px;
        height: 45px;
    }

    .ilyuhiuoh{
        font-family: Medium;
    font-weight: 500;
    font-size: 21px;
    line-height: 100%;
    color: #2d3133;
    }
    .nttl{
        /* margin-top: -20px; */
    }
    .vhomeIOP{
        margin-top: 25px;
    }
    .xtyfguh{
        display: block;
    }
    #jyhguyg{
        margin-top: 30px;
    }
    .MainHome{
        /* border: 1px solid yellow; */
        /* width: 70%; */
        margin-top: 2.5vh;
    }
    .cyfhv{
        width: 23px;
    height: 13px;
    /* position: absolute; */
    /* right: 40%; */
    /* bottom: 0; */
    /* margin-left: 15px; */
    margin-bottom: 70px;
    /* overflow: hidden; */
    }
    .letstalk{
        height: 50px;
 
    }

    .yturf7yug{
        margin-top: 5vh;
    }
  .Thirdsection{
    margin-top: 30px;
  }
    .brand3{
        margin-left: 0px;
        /* margin-top: 30px; */
        /* padding-left: 0px; */
        /* border: 1px solid red; */
    }

    .gfhfcv{
        display: inline;
    }
    .learmore{
 display: none;
    }
    .learmoretwo{
        display: block;
        margin-top: 60px;
        width: 200px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
        border-style: solid;
        border-image:  linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%)2;
    }
    .homesol1001{
        display: block;
    }
    .homesol{
        display: none;
    }

    .newcontent{
        width: 100%;
        font-size:19px; 

    }
    .solutions{
        width: 100%;
        margin-top: 30px;
       
    }

    .HEadliolo{
        margin-top: 7vh;
    }
    .Title{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        /* border: 1px solid red; */
        margin-top: 1vh;
    }

    .sevenko{
font-size: 35.508px;
    }
    #hiff{
        display: block;
        color: #2D3133;
font-family: Regular;
font-size: 21.508px;
font-style: normal;
font-weight: 400;
line-height: 22.525px; 
/* border: 1px solid red; */
        /* margin-top: 50px; */
    }
    #huhum{
        display: none;
    }
    .arewhoinsus{
        height: 35vh;
    }

  .ques{
/* border: 1px solid red; */

    color: #2D3133;
    font-family: Medium;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
}
.ques span{
    color: #0A71FE;
    font-family: Bold;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px; 
}
.sene{
    color: #2D3133;
    font-family: Medium;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
}
.sene span{
    color: #0A71FE;
    font-family: Medium;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px
}
.areinner{
    color: #2D3133;
font-family: Regular;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 112%;
}
.solutionp{
    font-size: 19px;
}
.soliutioninner{
    color: #2D3133;
font-family: Medium;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: 23px;
}
.dep{
    color: #2D3133;
font-family: Medium;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 21px;
}
.vghfg{
    color: #2D3133;
font-family: Medium;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 24px;
}
.ideastar{
    color: #2D3133;
font-family: Regular;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 19px; 
}
.brandgu{
    font-size: 21px;
    margin-top: 20px;
}
.Brandhuhu{
    color: #2D3133;
font-family: Regular;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
}

}
@media (max-width:750px) {
    .arewhoinsus{
        color: #2D3133;
font-family: Regular;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 26px;
    }
    .insutry{
        margin-top: 50px;
    }
    .bundles{
        display: grid;
        grid-template-columns: repeat(1 , 1fr);
        gap: 20px;
    }
}