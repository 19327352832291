@font-face {
  font-family: Regular;
  src: url("../public/Cera/Cera-Regular.ttf");
}
@font-face {
  font-family: Bold;
  src: url("../public/Cera/Cera-Bold.ttf");
}
@font-face {
  font-family: Light;
  src: url("../public/Cera/Cera-Light.ttf");
}
@font-face {
  font-family: Medium;
  src: url("../public/Cera/Cera-Medium.ttf");
}
@font-face {
  font-family: Thin;
  src: url("../public/Cera/Cera-Thin.ttf");
}
@font-face {
  font-family: BlackF;
  src: url("../public/Cera/Cera-Black.ttf");
}
html{
  height: -webkit-fill-available;
  height: 100%;
}
body{
  height: 100%;
  height: -webkit-fill-available;
}
main{
  min-height: 100%
}
a{
  text-decoration: none;
  color: #1a1a1a;
}
a:hover{
  color: #0A71FE;;
}
.abouonceOne{
  color: #000;
  font-family:Regular;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.Headerdiv::before{
  content: ' ';
  display: block;
  position: absolute;
  right:0;
  top: 0;
  width: 46%;
  height: 72vh;
  /* opacity: 0.04; */
  z-index: -9999;
  background-image: url('../public//images/layer.svg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  /* border: 1px solid red; */
}

@media (max-width:1440px) {
  .Headerdiv::before{
    content: ' ';
    display: block;
    position: absolute;
    right:0;
    top: 0;
    width: 60%;
    height: 72vh;
    /* opacity: 0.04; */
    /* z-index: -9999; */
    background-image: url('../public//images/layer.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* border: 1px solid red; */
  }
}
@media (max-width:700px) {
  .Headerdiv::before{
    content: ' ';
    display: block;
    position: absolute;
    right:0;
    top: 0;
    width: 277px;
    height:277px;
    /* opacity: 0.04; */
    /* z-index: -9999; */
    background-image: url('../public//images/layer.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* border: 1px solid red; */
  }
}