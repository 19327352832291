.Mainabout{
    margin-top: 100px;
}
.ghj{
    display: flex;
    margin-top: 70px;
}
.divin{
    color: #2D3133;
text-align: center;
font-family: Medium;
font-size: 42px;
font-style: normal;
font-weight: 500;
line-height: 47px; 
}
.lineabout{
    width: 1px;
height: 130px;
background: #2D3133;
}
.hgtfyf{
    display: none;
}
.aboutimage{
    width: 263.16px;
height: 33.8px;
}
.aboutimage img{
    width: 100%;
    height: 100%;
}
.aboutimahe2{
    width: 190px;
    height: 28px;
/* height: 10px; */
}
.jghfkvioi{
    height: 15%;
/* border: 1px solid red; */

}
.aboutimahe2 img{
    width: 100%;
    height: 100%;
}
.aboutimage3{
    width: 171.72px;
height: 33.8px;
/* border: 1px solid red; */
}
.aboutimage3 img{
    width: 100%;
    height: 100%;
}
.aboutbrand{
    width: 50%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   /* border: 1px solid yellow; */
}
.aboutbrand img{
width: 100%;
height: 100%;
}
.tydfh{
    height: 182px;
    width: 1px;
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    -webkit-text-fill-color: transparent;
}

.tdfg{
    width: 40%;
    height: 19vh;
    /* border: 1px solid green; */
    /* border-left:1px solid #2D3133 ; */
    /* border-right: 1px solid #2D3133; */
    display: flex;
    /* justify-content: center; */
}
.werf{
    width: 70%;
}

.sahgrfset{
    /* border: 1px solid red; */
    width: 42%;
    height: 19vh;
    display: flex;
    /* padding-left: 80px; */
    justify-content: end;
}
.aboutus{
    color: #2D3133;
font-family: Medium;
/* border: 1px solid red; */
font-size: 57px;
font-style: normal;
font-weight: 500;
line-height: 57px; /* 100% */
}
.aboutusINdus{
    color: #2D3133;
    font-family: Medium;
    /* border: 1px solid red; */
    font-size: 42px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: 57px; /* 100% */
}
.abouonce{
    color:#2D3133;
font-family:Regular;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 26px;
}
.abouonceOne{
    width: 80%;
    /* border: 1px solid red; */
    color: #2D3133;
    font-family: Medium;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}
.brandAbout{
    color: #000;
font-family: Medium;
font-size: 42px;
font-style: normal;
font-weight: 500;
line-height: 42px; 
}
.sideabout{
    color: #2D3133;
font-family: Regular;
font-size: 17.56px;
font-style: normal;
font-weight: 400;
/* width: 70%; */
line-height: 21px; 
}
@media (max-width:1528px) {
    .abouonceOne{
        width: 100%;
        /* border: 1px solid red; */
    }
    
    .aboutbrand{
        width: 65%;
    }
    .tydfh{
position: relative;
top: 15px;
    }
}
@media (max-width:1440px) {
    .aboutbrand{
        width: 70%;
        /* border: 1px solid red; */
    }
}
@media (max-width:1200px) {
    .aboutbrand{
        width: 80%;
    }
    .aboutimage{
        height: 28px;
        width: 80%;
    }
    .aboutimahe2{
        height: 28px;
        width: 80%;
    }
    .aboutimage3{
        height: 28px;
        width: 80%;
    }
}

@media (max-width:800px) {
 
    .tydfh{
        position: relative;
        top: 30px;
            }
    .aboutbrand{
        width: 90%;
    }

}
@media (max-width : 700px) {
    .ghj{
        display: flex;
        margin-top: 44px;
    }
    .Mainabout{
        margin-top: 10px;
    }
    .hgtfyf{
        display: block;
    }
    .hgtfyf1{
        display: none;
    }
    .aboutusINdus{
        color: #2D3133;
        font-family: Medium;
        /* border: 1px solid red; */
        font-size: 24px;
        font-style: normal;
        text-align: start;
        font-weight: 500;
        line-height:  112%; /* 100% */
    }
    .abouonceOne{
        font-size: 19px;
        line-height:  112%;
    }
    .tydfh{
        display: none;
    }
    .aboutbrand{
        width: 100%;
    }
    .brandAbout{
        color: #000;
        font-family: Medium;
        font-size: 29px;
        font-style: normal;
        font-weight: 500;
        line-height: 29px;
    }
   .abouonce{
    color: #000;
font-family: Regular;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
   }
    .aboutus{
        font-size: 24px;
        line-height: 32px;
        /* margin-top: 80px; */
        /* font-size: 29px; */
    }
    .aboutbrand{
        display: inline;
    }
    .aboutimage{
        width: 50%;
        margin-top: 30px;
    }
    .aboutimahe2{
        width: 190px;
        height: 28px;
        margin-top: 30px;
    }
    .aboutimage3{
        margin-top: 30px;
        width: 171.72px;
        height: 33.8px;
        margin-left: -5px;
    }
    .vghb{
        width: 100%;
    border-bottom:1px solid #2D3133 ;

    }
    .hggyfug{
        width: 100%;
    border-bottom:1px solid #2D3133 ;

    }
    .tdfg{
        width: 100%;
        border-left:none ;
        border-right: none;
        display: inline;
    }
    .sahgrfset{
        display: inline;
    }
    .Mainabout{
        margin-top: 40px;
    }
    .cghfg{
        width: 100%;
        /* border: 1px solid red; */
    }
    .sideabout{
        color: #2D3133;
font-family: Regular;
font-size: 17.56px;
font-style: normal;
font-weight: 400;
line-height: 23px;
    }
    .divin{
        color: #2D3133;
        text-align: center;
        font-family: Medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 122%; 
    }
 
}
