.pro1{
    width: 70%;
    /* display: flex; */
    margin-top: 12vh;
    /* border: 1px solid red; */
}
.gap{
    /* height: 10vh; */

}
.pro2{
    width: 100%;
    height: 50vh;
}

.pro21{
    width: 50vh;
    /* border: 1px solid red; */

    height: 100%;
}
.pro21 img{
    width: 100%;
    height: 100%;
}

.pro3{
    margin-top: 5%;
}
.pro31{
    color: #2D3133;
    font-family: Medium;
    height: 7vh;
    /* border: 1px solid red; */
    font-size: 49px;
    font-style: normal;
    font-weight: 500;
    line-height: 49px;
    display: flex;
    justify-content: space-between;
}
.ytukjgb{
    width: 70%;
}
.vkjkgjb{
    width: 181.71px;
    height: 236.98px;
    margin-top: -70px;
}
.vkjkgjb img{
width: 100%;
height: 100%;
}
.vyjukilkhgb{
    width: 238px;
    height: 150px;
   margin-top: -58px;
}
.vyjukilkhgb img{
    width: 100%;
    height: 100%;
}
.pro32{
    color: #2D3133;
    font-family: Regular;
    font-size: 29px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px; 
}
.pro33{
     margin-top: 10%;
}
.pro33 img{
    width: 100%;
    height: 100%;

}



.pro4{
    width: 100%;
    height: 35vh;
    margin-top: 10%;
}
.pro41{
    width: 50%;
    /* border: 1px solid red; */
    height:30vh ;
}
.pro41 img{
    width: 100%;
    height: 100%;
}



.pro5{
    margin-top: 5%;
}


.pro6{
    margin-top: 10%;
}
.pro61 img{
    width: 100%;
    height: 100%;

}

.pro7{
    margin-top: 70px;
   margin-bottom: 5%;
}
.pro71{
    color: #2D3133;
    text-align: center;
    font-family: Medium;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 47px; 
}
#pro34{
    display: none;
}
@media (max-width : 1285px) {
    .pro1{
        width: 90%;
        height: auto;
        /* display: flex; */
        margin-top: 6.5vh;
    }
    .pro31{
        color: #0A71FE;
        font-family: Medium;
        height: 7vh;
        /* border: 1px solid red; */
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 49px;
        display: flex;
        justify-content: space-between;
    }
    .pro32{
        font-size: 20px;
    }
}

@media (max-width:701px) {
    .vkjkgjb{
        width: 78px;
        height: 99px;
        margin-top: -20px;
    }

    .vyjukilkhgb{
        width: 86px;
        height: 54px;
       margin-top: -20px;
    }
    .pro4{
        /* border: 1px solid red; */
        height: 33vh;
    }
    .pro41{
        width: 100%;
        /* border: 1px solid red; */
    
        height: 30vh;
    }
    .pro1{
        width: 90%;
        height: auto;
        /* display: flex; */
        margin-top: 6.5vh;
    }

    .pro2{
        width: 100%;
        height:40vh;
        /* border: 1px solid red; */
    }
    .pro21{
        width: 100%;
        /* border: 1px solid red; */
    
        height:20vh;
    }
    .pro21 img{
        width: 100%;
        height: 35vh;
    }
    .pro3{
        width: 100%;
        height: auto;
    }
    #pro34{
        display: block;
        width: 100%;
        height: auto;
/* margin-bottom: -20px; */
    }
    .pro3 #pro34 img{
        /* border: 1px solid red; */
        margin-top: 10%;
        
    }
    #pro33{
        display: none;
    }
    .pro31{
        color: #2D3133;
        font-family: Medium;
        font-size: 29px;
        font-style: normal;
        font-weight: 500;
        line-height: 29px;
    }
    .pro32{
        margin-bottom: -40px;
        color: #2D3133;
        font-family: Regular;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; 
        margin-top: 5px;
    }
    .pro71{
        color: #2D3133;
        text-align: center;
        font-family: Medium;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 29px; 
    }
    .pro6{
        width: 100%;
        height: auto;
      /* border: 1px solid red; */
    }
    #pro61{
        /* margin-bottom: -40px; */
        display: block;
        width: 100%;
        height: auto;

    }
    .pro6 #pro34 img{
        /* border: 1px solid red; */
        /* margin-top: 10%; */
        
    }

}