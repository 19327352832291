
.seeourprocess{
    width: 25%;
height: 50px;
/* border: 3px solid #2D3133; */
display: flex;
justify-content: center;
align-items: center;
color: #2D3133;
font-family: Regular;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 21px;
border-style: solid;
border-image:  linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%)2;
}
.solutionsPage{
  width: 100%;
}

.solutionsPage img{
    width: 100%;
    height: 100%;
}
.seeourprocess:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    color: #ffff;
    color: #fff;
}
.Thirdsectionsolution{
    margin-top: 100px;
}
.indus1{
    display: none;
}
.ghjb{
    width: 100%;
    display: flex;
    margin-top: 80px;
    justify-content: flex-end;
    margin-top: -6.5vh;
    
}
.ghjbcon{
    width: 100%;
    display: flex;
    margin-top: 80px;
    justify-content: center;
}
.Solutoin{
    width: 60%;
    color: #2D3133;
font-family: Medium;
font-size: 42px;
font-style: normal;
font-weight: 500;
line-height: 42px;
}
.solutininner{
    color: #2D3133;
font-family: Regular;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 21px;
}
.solutininnerTwo{
    color: #2D3133;
    font-family: Medium;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
}
@media (max-width:800px) {
 .indus1{
    display: block;
 }
 .indus{
    display: none;
 }
.ghjb{
    display: flex;
    justify-content: center;
    margin-top: 8vh;
}
.seeourprocess{
    width: 192px;
height: 45px;
}
    .Thirdsectionsolution{
        margin-top: 40px;
    }
    .Solutoin{
        width: 100%;
        color: #2D3133;
        font-family: Medium;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}