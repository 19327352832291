footer{
    background-color: #1a1a1a;
    width: 100%;
    height: auto;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}
.footerinput{
    width: 242px;
height: 50px;
display: flex;
justify-content: space-between;
align-items: center;
padding-left: 10px;
border: 3px solid #EAE9E9;
z-index: 9999999999999999;
}
.yufgvFooter{
    position: absolute;
    z-index: 99999999;
    right: 95px;
    overflow: hidden;
    /* bottom: 0; */
    /* bottom: 10px; */
    /* border: 1px solid red; */
    width: 13.5%;
    height: 34vh;
}
.jhgvf{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #1a1a1a;

}
.MainHomefooter{
    color: white;
        /* border: 1px solid red; */
        width: 90%;
}
.rgtf{
    display: none;
}
.finghgvh{
    position: absolute;
    top: -1500px;
    animation-name: regfrwef;
animation-duration: 0.5s;
animation-iteration-count: 1;
width: 99%;
/* opacity: 0.1; */
animation-timing-function: ease;
overflow: hidden;
/* visibility: hidden; */
}
@keyframes regfrwef {
    0%{
        transform: translateY(500px)
    }
    100%{
        transform: translateY(0);
    }
}
.jkjirfdgv{
    border: 1px solid #2D3133;
    width: 100%;
    border-radius: 9px;
    height: auto;
    background-color: #fff;
    /* overflow: scroll; */
    position: absolute;
   
    top: 70px;
z-index: 9999999;
animation-name: frewfcrew;
animation-duration: 1s;
animation-iteration-count: 1;
animation-timing-function: ease;
}
@keyframes frewfcrew {
    0%{
        transform: translateY(-200px)
    }
    100%{
        transform: translateY(0);
    }
}
.lankwera4gt{
    color: #141414;
    /* color: #2D3133; */
font-family: Regular;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 21px; 
    /* display: flex; */
    padding: 12px;
    border: 1px solid #141414;
}
.tyjhdfv{
    margin-bottom: 60px;
}
#scrolkjo{
    width: 45px;
    height: 50px;
    margin-bottom: 60px;
  border: 1px solid #1a1a1a;
  box-shadow: none;
}
.headfooter{
    color: #EAE9E9;
font-family: Medium;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 21px;
}
.footer{
    width: 90%;
    /* border: 1px solid yellow; */
}
.footerinner{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}
.footerlogo{
    width: 242.999px;
height: 34.565px;
}
.footerlogo img {
    width: 100%;
    height: 100%;
}
.Footerlan{
    width: 21.357px;
height: 26px;
padding-right: 15px;
}
.Footerlan img{
    width: 100%;
    height: 100%;
}
.lan{
    /* color: white; */
    color: #EAE9E9;
font-family: Regular;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 21px;
}
.Footerline{
    width: 100%;
    background: #EAE9E9;
    height: 1px;
}
.sitema{
    background-color:#1a1a1a ;
    width: 100%;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.siteinner{
    width: 90%;
    display: flex;
    color: white;
    justify-content: space-between;
}
.footerinfo{
    display: flex;
    margin-top: 60px;
    color: white;
}
.rightinfo{
    width: 25%;
    /* border: 1px solid red; */
}
.center{
    /* border: 1px solid red; */
    width: 25%;
}
.footerinfohun{
    padding-left: 30px;
}
.addres{
    width: 50%;
    color: #EAE9E9;
    font-family: Regular;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    padding-bottom: 10px;
}
.colllection1{
    color: #EAE9E9;
font-family: Regular;
font-size: 17px;
font-style: normal;
font-weight: 400;
}
.colllection{
    margin-top: 10px;
    color: #EAE9E9;
font-family: Regular;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 21px;
}
.colllection:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;  
}
.colllection1:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;  
}
.sidefoo:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;  
}
.addres:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;  
}
.sidefoo{
    color: #EAE9E9;
font-family: Regular;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 21px;

}
.sidefoo1{
    color: #EAE9E9;
    font-family: Regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;    
}
.sidefoo2{
    margin-left: 10px;
    margin-right: 10px;
    width: 2px;
    height: 15px;
    background: #EAE9E9;
}
@media (max-width:1440px) { 
    .yufgvFooter{
        position: absolute;
        z-index: 99999999;
        right: 75px;
        overflow: hidden;
        /* bottom: 0; */
        /* bottom: 10px; */
        /* border: 1px solid red; */
        width: 17.5%;
        height: 32vh;
    }
}
@media (max-width:1000px) { 
    .yufgvFooter{
        position: absolute;
        z-index: 99999999;
        right: 52px;
        overflow: hidden;
        /* bottom: 0; */
        /* bottom: 10px; */
        /* border: 1px solid red; */
        width: 26%;
        height: 32vh;
    }
}

@media (max-width:900px) {
   
    .rightinfo{
        width: 50%;
        /* border: 1px solid red; */
    }
}
@media (max-width:600px) {
    .Footerlan{
        width: 17px;
    height: 21px;
    padding-right: 15px;
    }
    .hjyjfv{
        font-size: 10px;
    }
   
    .yufgvFooter{
        position: absolute;
        z-index: 99999999;
        right: 30px;
        /* right: 52px; */
        overflow: hidden;
        /* bottom: 0; */
        /* bottom: 10px; */
        /* border: 1px solid red; */
        width: 46%;
        height: 50vh;
    }
    .jkjirfdgv{
        border: 1px solid #2D3133;
        width: 100%;
        border-radius: 9px;
        height: auto;
        background-color: #fff;
        /* overflow: scroll; */
        position: absolute;
        top: 10px;
    z-index: 9999999;
    }
  
    .rightinfo{
   margin-top: 45px;
    }
    .footerinfo{
        display: inline;
    }
    .rightinfo{
        width: 50%;
        /* border: 1px solid red; */
    }
    .center{
        /* border: 1px solid red; */
        width: 50%;
    }
    .addres{
        width: 100%;
        /* border: 1px solid red; */
    }
    .footerinfohun{
        padding-left: 0;
        margin-top: 25px;
    }
    .footerlogo{
        width: 191px;
        height: 27.168px;
    }

  
    .footerinput{
 
        width: 170px;
        height: 40px;
margin-top: 30px;
    }
    .footerinner{
        margin-top: 50px;
        display: inline;
    }
    .footer{
        margin-top: 40px;
    }
}