nav{
    width: 100%;
    height: 12vh;
    display: flex;
    overflow-x: hidden;
    justify-content: center;
    /* border-bottom: 1px solid rgba(45, 49, 51, 0.20); */

    /* position: -webkit-sticky;
  position: sticky;
  top: 0; */
}
.Navbar2{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;   
}
.Navbar{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.NavCollection{
    display: flex; 
    align-items: center;
    font-family: Regular
}
.hgyhrfuthig{
    width: 100%;
    height: 1px;
    background: #EAE9E9 ;

}
.logohead{
    width: 280px;
    height: 41.25px;
}
.logoheadONe{
    width: 200px;
    height: 28px;
    /* border: 1px solid red; */
}
.logoheadONe img{
    width: 100%;
    height: 100%;
}
.logohead img{
    width: 100%;
    height: 100%;
}
.languagelogo{
    width: 23px;
    height: 28px;
    padding-left: 30px;
    cursor: pointer;
    /* border: 1px solid red; */
}
.ytfghv{
    width: 20px;
    height: 20px;
    padding-right: 15px;
    /* margin-left: -10px; */
}

.ytfghv img{
  width: 100%;
  height: 100%;
}
.dropn{
    border: 1px solid #2D3133;
    width: 99%;
    border-radius: 9px;
    overflow: hidden;
    height: auto;
    background-color: #fff;
    /* overflow: scroll; */
    position: absolute;
   
    /* top: 100px; */
z-index: 9999999;
animation-name: frewfcrew;
animation-duration: 1s;
animation-iteration-count: 1;
animation-timing-function: ease;
overflow: hidden;

/* text-align: center; */
}
@keyframes frewfcrew {
    0%{
        transform: translateY(-200px)
    }
    100%{
        transform: translateY(0);
    }
}
.yufgv{
    position: fixed;
    z-index: 99999999;
    top: 120px;
    overflow: hidden;
    /* left: 0; */
    right: 100px;
    /* border: 1px solid red; */
    width: 15%;
    height: 40vh;
    
}
.lank{
    color: #141414;
    /* color: #2D3133; */
font-family: Regular;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 21px; 
    /* display: flex; */
    padding: 16px;
    border: 1px solid #141414;
}
#jipo{
    padding-left:15px;
}
.cgfhfv{
    display: flex;
}
.cghvj{
    padding-top: 20px;
    cursor: pointer;
}
.cghvj:hover{
    text-decoration: underline;
    color: #0A71FE;
}
.cgfhfvwegd{
    display: flex;
}

.finghgvh{
    position: absolute;
    top: -500px;
    animation-name: regfrwef;
animation-duration: 0.5s;
animation-iteration-count: 1;
width: 99%;
/* opacity: 0.1; */
animation-timing-function: ease;
/* overflow: hidden; */
/* visibility: hidden; */
}
.dropnewf{
    display: none;
}
@keyframes regfrwef {
    0%{
        transform: translateY(500px)
    }
    100%{
        transform: translateY(0);
    }
}
.languagelogo img{
    width: 100%;
    height: 100%;
}

.connect{
    width: 189px;
    height: 50px;
    display: flex;
    font-family:Medium;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-image:  linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%)2;
    margin-left: 30px;
}
.connectTwo{
    width: 200px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-image:  linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%)2;
    color: #fff;
    /* color: #2D3133; */
font-family: Medium;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 21px; 
}
.connectTwo:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);

    color: #2D3133
}
.connect:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    color: #ffff;
}
.pages{
    padding-left: 30px;
    color: #2D3133;
    font-family:Medium;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
    /* border: 1px solid red; */
    border: none;
    background: transparent;
    /* text-decoration: underline; */
    /* width: 100px; */
}
.ytedrufyt6 a{
    color: #2D3133;
    font-family: Medium;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
    /* border: 1px solid red; */
    border: none;
    background: transparent;
    text-decoration: underline;
    /* width: 100px; */
}

.pages a:active{
text-decoration: underline;
}
.pages2{
    padding-left: 35px;
    color: #2D3133;
    font-family: Medium;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
    text-decoration: underline;
}
.froppnav{
    text-decoration: underline;
    padding-left: 35px;
    color: #0A71FE;
    font-family: Regular;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
}
.pages:hover{
    background: linear-gradient(135deg, hsla(224, 100%, 90%, 1) 0%, hsla(326, 100%, 79%, 1) 27%, hsla(221, 99%, 42%, 1) 78%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;  
}
.hamburger{
    display: none;
}
.linehead{
    padding-left: 30px;
  
}
.lineinter{
    width: 1px;
height: 50px;
background: rgba(45, 49, 51, 0.20);
}
.lineinterTwo{
    /* margin-top: 30px; */
    width: 1px;
height: 40px;
background:white;
/* padding-top: 30px; */
}
.lineheadTwo{
    display: none;
}
.Menutwo{
    position: absolute;
    left: -100vh;
}
.Menu{
    position: fixed;
    top: 0vh;
    right: 0;
    width: 80%;
    height: 100vh;
    overflow: hidden;
    background-color:#2D3133;
    /* border: 1px solid red; */
    /* overflow: hidden; */
    z-index: 9999999999;
    color: white;
    display: flex;
    justify-content: center;
    animation-name: menuiteam;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}
@keyframes menuiteam {
    0%{
        transform: translateX(800px);
    }
    100%{
        transform: translateX(0);
    }
}

.pagesTwo{
    margin-top: 35px;
    /* padding-left: 35px; */
    color: #fff;
    font-family: Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
}
.Menucolle{
    margin-top: 80px;
}
.MenuHeade{
    width: 90%;
    /* border: 1px solid yellow; */
}
.MenuInnter{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}
.menulogo{
    width: 137px;
height: 19px;
}
.menulogo img{
    width: 100%;
    height: 100%;
}
.MenuIteam{
    display: flex;
    align-items: center;
}
@media (max-width:1440px) {
    .yufgv{
        width: 20%;
        height: 60vh;
        position: fixed;
        z-index: 99999999;
        top: 120px;
        right: 70px;
    }

}
@media (max-width:1350px) {

    .yufgv{
        position: fixed;
        z-index: 99999999;
        top: 150px;
        overflow: hidden;
        /* left: 10px; */
        right: 68px;
        /* border: 1px solid red; */
        width: 30%;
        height: 45vh;
    }
    .dropn{
        border: 1px solid #2D3133;
        width: 90%;
        border-radius: 9px;
        height: auto;
        background-color: #fff;
        /* overflow: scroll; */
        position: absolute;
        right: 0;
        top: 0;
    }
}
@media (max-width:1208px) {

    .pages{
      display: none;
    }  
    .lineheadTwo{
        display: block;
        padding-left: 25px;
    }
    .connect{
        display: none;
    }
    .linehead{
        display: none;
    }
    .hamburger{
        display: block;
        padding-left: 20px;
    }
}
@media (max-width:600px) {
    .languagelogo{
        width: 19px;
        height: 24px;
        padding-left: 30px;
        cursor: pointer;
        /* border: 1px solid red; */
    }
    .logoheadONe{
        width: 137px;
        height: 19px;
        /* border: 1px solid red; */
    }
    .Navbar{
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .connectTwo{
        width: 156px;
        height: 45px;

    }
    .yufgv{
        position: fixed;
        z-index: 99999999;
        top: 100px;
        overflow: hidden;
        /* left: 10px; */
        right: 28px;
        /* border: 1px solid red; */
        width: 60%;
        height: 40vh;
    }
    .dropn{
        border: 1px solid #2D3133;
        width:98%;
        border-radius: 9px;
        height: auto;
        background-color: #fff;
        /* overflow: scroll; */
        position: absolute;
        right: 0;
        top: 0;
    }
    .finghgvh{
        position: absolute;
        top: -500px;
        animation-name: regfrwef;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    width: 80%;
    /* opacity: 0.1; */
    animation-timing-function: ease;
    /* overflow: hidden; */
    /* visibility: hidden; */
    }
    .logohead{
        width: 222px;
        height: 28.514px;
    }
}
@media (max-width:400px) {
    .logohead{
        width: 180px;
        height: 28.514px;

    }
}